<template>
    <div v-loading="loading" class="right-content">
        <div class="mod-block">
            <span>机构名称</span>
            <input v-model="position"/>
        </div>
        <div class="mod-block">
            <span>需求备注</span>
            <input v-model="salary"/>
        </div>
        <div class="mod-block">
            <span>联系人</span>
            <input v-model="company_name"/>
        </div>
        <div class="mod-block">
            <span>职务</span>
            <input v-model="certificate_subsidy"/>
        </div>
        <div class="mod-block">
            <span>联系电话</span>
            <input v-model="place"/>
        </div>
        <div class="mod-block">
            <span>电子邮件</span>
            <input v-model="email"/>
        </div>
        <div class="btn" @click="next">提交</div>
    </div>
</template>
<script>
export default {
    props:{
        type:{
            type:String,
            default:() => 'xuesheng',
        },
    },
    data() {
        return {
            loading: false,
            position:'',
            salary:'',
            company_name:'',
            certificate_subsidy:'',
            place:'',
            email:''
        }
    },
    methods: {
        // 添加企业库
        async next(){
            if(this.position === ''){
                this.$toast('请输入机构名称');
                return false;
            }
            if(this.salary === ''){
                this.$toast('请输入需求备注');
                return false;
            }
            if(this.company_name === ''){
                this.$toast('请输入联系人');
                return false;
            }
            if(this.certificate_subsidy === ''){
                this.$toast('请输入职务');
                return false;
            }
            if(this.place === ''){
                this.$toast('请输入联系电话');
                return false;
            }
            if(this.email === ''){
                this.$toast('请输入电子邮箱');
                return false;
            }
            const params = {
                org_name: this.position,
                desc: this.salary,
                linker: this.company_name,
                position: this.certificate_subsidy,
                link_phone: this.place,
                email: this.email
            }
            this.loading = true;
            const res = await this.$http.post('/api/user/submitDemand', params)
            this.loading = false;
            if(res){
                this.$toast('您的需求已提交，请等待回复，谢谢！', {duration: 3000});
                const tem = setTimeout(() => {
                    window.location.reload();
                    clearTimeout(tem);
                }, 3000);
                
            }
        }
    },
}
</script>
<style lang="scss" scoped>
.right-content{
    margin-top: 20px;
    padding: 32px 0 43px 0;
    width: 100%;
    background: #3884D3;
    .mod-block{
        span{
            display: inline-block;
            width: 65px;
        }
        width:280px;
        margin:auto;
        margin-bottom: 10px;
        font-size: 14px;
        color: #fff;
    }
}
.btn{
    width: 100px;
    text-align: center;
    height: 30px;
    line-height: 30px;
    background: #00FFFF;
    color: #000;
    font-weight: bold;
    font-size: 14px;
    margin: auto;
    margin-top:20px;
    cursor: pointer;

}
</style>
