<template>
    <div class="info-list">
        <Header/>
        <HeaderTitle title="需求登记"/>
        <div class="main-page-content contain-left-right">
            <RightContent />
        </div>
        <div class="blank20"/>
        <Footer/>
    </div>
</template>
<script>
import HeaderTitle from './header';
import RightContent from './demandContent.vue'
export default {
    data() {
        return {
            type:1,
        }
    },
    components:{
        HeaderTitle,
        RightContent,
    },
    beforeMount() {
        if(this.$route.query.type){
            this.type = Number(this.$route.query.type);
        }
    },
}
</script>
<style lang="scss" scoped>

</style>
